var render = function () {
  var _vm$getAgencyData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Stack', [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "MapPinIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.domesticTicket')))])];
      },
      proxy: true
    }])
  }, [_c('FeeTab', {
    attrs: {
      "ticket-type": "INLAND",
      "fee-configs-data": _vm.feeConfigsData.inland,
      "agency-id": _vm.agencyId
    },
    on: {
      "refetch-data": _vm.getFeeConfigsData
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "NavigationIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.internationalTicket')))])];
      },
      proxy: true
    }])
  }, [_c('FeeTab', {
    attrs: {
      "ticket-type": "INTERNATIONAL",
      "fee-configs-data": _vm.feeConfigsData.international,
      "agency-id": _vm.agencyId
    },
    on: {
      "refetch-data": _vm.getFeeConfigsData
    }
  })], 1)], 1), _vm.canModifyF1AgencyFee ? _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingF1AgencyFee
    }
  }, [_c('BCard', [_c('BFormCheckbox', {
    staticClass: "custom-control-success cursor-pointer",
    attrs: {
      "name": "check-button",
      "inline": "",
      "switch": "",
      "checked": (_vm$getAgencyData = _vm.getAgencyData) === null || _vm$getAgencyData === void 0 ? void 0 : _vm$getAgencyData.hideFeeAllChild
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onChangeF1AgencyFeeHandle($event);
      }
    }
  }, [_c('div', {
    staticClass: "fw-800 text-16px cursor-pointer"
  }, [_vm._v(" " + _vm._s(_vm.$t("fee.f1AgencyFee")) + " ")])])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }